import { Switch } from '@headlessui/react';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { PError } from '../../apiTypes';
import * as FormStyles from '../../styles/FormStyles';
import { classNames, ReactChildren, getLocale } from '../../utils';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import LoadingAnimation from '../LoadingAnimation';
import LoadingFullScreen from '../LoadingFullScreen';
import { XMarkIcon } from '@heroicons/react/20/solid';
import DatePicker from '../../DatePicker';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { formatDateUtc } from '../../dateUtils';

export function BackLabel() {
    return (
        <span className="flex flex-row items-center font-haas uppercase">
            <ChevronLeftIcon className="m-1 h-5 w-5" /> Back
        </span>
    );
}

type FormParams = {
    onSubmit: () => void;
    children: ReactChildren;
    gapSize?: number;
};

export default function Form({ onSubmit, children, gapSize = 1 }: FormParams) {
    const submitHandler = async (evt: React.SyntheticEvent) => {
        evt.preventDefault();
        onSubmit();
    };

    return (
        <form className={FormStyles.Form(gapSize)} onSubmit={submitHandler}>
            {children}
        </form>
    );
}

type LabelProps = {
    name: string;
    children: ReactChildren;
    className?: string | null;
    required?: boolean;
};

export function Label(props: LabelProps) {
    return (
        <label className={props.className ?? FormStyles.Label} htmlFor={props.name}>
            {props.children} {props.required && '*'}
        </label>
    );
}

type onChangeEvent = (name: string, value: string) => void;

type TextInputParamsBase = {
    name: string;
    className?: string | null;
    value: string | number | null | undefined;
    placeholder?: string | undefined;
    readOnly?: boolean;
    hasError?: boolean;
    maxLength?: number;
    onChange?: onChangeEvent;
    onBlur?: onChangeEvent;
    onFocus?: onChangeEvent;
    disabled?: boolean;
    id?: string;
};

type TextAreaInputParams = {
    rows?: number;
} & TextInputParamsBase;

export function TextAreaInput(params: TextAreaInputParams) {
    const changeHandler = (evt: React.FormEvent<HTMLTextAreaElement>) => {
        if (params.onChange && !params.readOnly) {
            const v = evt.currentTarget.value;
            params.onChange(params.name, v);
        }
    };

    const blurHandler = (evt: React.FormEvent<HTMLTextAreaElement>) => {
        if (params.onBlur && !params.readOnly) {
            const v = evt.currentTarget.value;
            params.onBlur(params.name, v);
        }
    };

    const focusHandler = (evt: React.FormEvent<HTMLTextAreaElement>) => {
        if (params.onFocus && !params.readOnly) {
            const v = evt.currentTarget.value;
            params.onFocus(params.name, v);
        }
    };

    const classes = [params.className];

    if (params.hasError) {
        classes.push(FormStyles.InputError);
    } else {
        classes.push(FormStyles.Input, 'bg-white');
    }

    return (
        <textarea
            id={params.id}
            className={classNames(classes)}
            name={params.name}
            value={params.value ?? ''}
            maxLength={params.maxLength}
            placeholder={params.placeholder}
            readOnly={params.readOnly}
            disabled={params.disabled}
            rows={params.rows}
            onChange={changeHandler}
            onBlur={blurHandler}
            onFocus={focusHandler}
        />
    );
}

type TextInputParams = {
    type?: React.HTMLInputTypeAttribute | null;
    minValue?: number;
} & TextInputParamsBase;

export function TextInput(params: TextInputParams) {
    const inputType = params.type ?? 'text';

    const changeHandler = (evt: React.FormEvent<HTMLInputElement>) => {
        const v = evt.currentTarget.value;
        if (params.type === 'number' && params.maxLength && v.length > params.maxLength) {
            return;
        }
        if (params.onChange && !params.readOnly) {
            params.onChange(params.name, v);
        }
    };

    const blurHandler = (evt: React.FormEvent<HTMLInputElement>) => {
        if (params.onBlur && !params.readOnly) {
            const v = evt.currentTarget.value;
            params.onBlur(params.name, v);
        }
    };

    const focusHandler = (evt: React.FormEvent<HTMLInputElement>) => {
        if (params.onFocus && !params.readOnly) {
            const v = evt.currentTarget.value;
            params.onFocus(params.name, v);
        }
    };

    const numberInputOnWheelPreventChange = (e: React.WheelEvent<HTMLInputElement>) => {
        // Prevent the input value change
        e.currentTarget.blur();

        // Prevent the page/container scrolling
        e.stopPropagation();

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            (e.target as HTMLInputElement)?.focus();
        }, 0);
    };

    const classes = [params.className];

    if (params.hasError) {
        classes.push(FormStyles.InputError);
    } else {
        classes.push(FormStyles.Input, 'bg-white');
    }

    return (
        <input
            id={params.id}
            className={classNames(...classes)}
            type={inputType}
            name={params.name}
            value={params.value ?? ''}
            placeholder={params.placeholder}
            maxLength={params.maxLength}
            readOnly={params.readOnly}
            disabled={params.disabled}
            min={params.minValue}
            onChange={changeHandler}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onWheel={inputType === 'number' ? numberInputOnWheelPreventChange : undefined}
        />
    );
}

type onCheckChangeEvent = (name: string, value: boolean) => void;

type CheckInputParams = {
    name: string;
    ariaLabel?: string;
    className?: string | null;
    value: boolean | null | undefined;
    readOnly?: boolean;
    onChange?: onCheckChangeEvent;
    onBlur?: onCheckChangeEvent;
};

export function CheckInput(params: CheckInputParams) {
    const changeHandler = (evt: React.FormEvent<HTMLInputElement>) => {
        if (params.onChange && !params.readOnly) {
            const v = evt.currentTarget.checked;
            params.onChange(params.name, v);
        }
    };

    const blurHandler = (evt: React.FormEvent<HTMLInputElement>) => {
        if (params.onBlur && !params.readOnly) {
            const v = evt.currentTarget.checked;
            params.onBlur(params.name, v);
        }
    };

    const styles = params.className ?? params.readOnly ? FormStyles.CheckboxReadonly : FormStyles.Checkbox;

    return (
        <input
            className={styles}
            aria-label={params.ariaLabel}
            type="checkbox"
            name={params.name}
            checked={params.value ?? false}
            readOnly={params.readOnly}
            onChange={changeHandler}
            onBlur={blurHandler}
        />
    );
}

type ToggleInputParams = {
    name: string;
    ariaLabel?: string;
    className?: string | null;
    checked: boolean;
    readOnly?: boolean;
    onChange: (checked: boolean) => void;
};

export function ToggleInput(params: ToggleInputParams) {
    const [checked, setChecked] = useState(params.checked);

    const checkedHandler = (checked: boolean) => {
        if (!params.readOnly) {
            setChecked(checked);
            params.onChange(checked);
        }
    };

    useEffect(() => {
        if (params.checked !== checked) {
            setChecked(params.checked);
        }
    }, [params.checked]);

    return (
        <Switch
            aria-label={params.ariaLabel}
            checked={checked}
            onChange={checkedHandler}
            className={classNames(
                checked ? 'bg-primary-600' : 'bg-gray-200',
                'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2'
            )}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={classNames(
                    checked ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
            />
        </Switch>
    );
}

type SelectInputParams = {
    name: string;
    className?: string | null;
    ariaLabel?: string;
    type?: string | null;
    value: string | null | undefined;
    readOnly?: boolean;
    hasError?: boolean;
    children: ReactChildren;
    onChange?: onChangeEvent;
    onBlur?: onChangeEvent;
    placeholder?: string;
    isClearable?: boolean;
    defaultValue?: string;
};

/** Wrapped version of basic browser select element (aka: a dropdown). Good for a small list of items to choose for, does not support type-ahead */
export function SelectInput(params: SelectInputParams) {
    const changeHandler = (evt: React.FormEvent<HTMLSelectElement>): void => {
        if (!params.readOnly) params.onChange?.(params.name, evt.currentTarget.value);
    };

    const blurHandler = (evt: React.FormEvent<HTMLSelectElement>): void => {
        if (!params.readOnly) params.onBlur?.(params.name, evt.currentTarget.value);
    };

    const clearHandler = (): void => {
        params.onChange?.(params.name, params.defaultValue ?? '');
    };

    const emptyValue = params.defaultValue ?? '';
    const isClearButtonShown = !!params.isClearable && !params.readOnly && params.value !== emptyValue;

    return (
        <div className="relative inline-flex items-center">
            <select
                aria-label={params.ariaLabel}
                className={`${params.hasError ? FormStyles.InputError : FormStyles.Input} ${
                    isClearButtonShown ? 'pr-16' : ''
                } flex-auto`}
                name={params.name}
                value={params.value ?? emptyValue}
                onChange={changeHandler}
                onBlur={blurHandler}
            >
                {!!params.placeholder && (
                    <option hidden value={emptyValue}>
                        {params.placeholder}
                    </option>
                )}
                {params.children}
            </select>
            {isClearButtonShown && (
                <button
                    type="button"
                    onClick={clearHandler}
                    className="absolute right-10 h-5 w-5 bg-transparent text-black"
                >
                    <XMarkIcon />
                </button>
            )}
        </div>
    );
}

type DateInputParams = {
    name: string;
    value: string | null;
    isValid?: boolean;
    showTooltip?: boolean;
    tooltipText?: string;
    disabled?: boolean;
    readonly?: boolean;
    className?: string;
    minDate?: Date | undefined;
    onChange: (name: string, value: DateValueType) => void;
};

export function DateInput(params: DateInputParams) {
    return (
        <DatePicker
            i18n={getLocale()}
            startDate={params.value ? formatDateUtc(params.value) : undefined}
            endDate={params.value ? formatDateUtc(params.value) : undefined}
            isValid={params.isValid}
            asSingle={true}
            showTooltip={params.showTooltip}
            tooltipText={params.tooltipText}
            inputName={params.name}
            disabled={params.disabled}
            readOnly={params.readonly}
            containerClassName={params.className}
            minDate={params.minDate}
            onChange={params.onChange}
        />
    );
}

type LoadingParams = {
    isLoading: boolean;
    fullscreen?: boolean;
};

/** Displays a standard "Loading..." message */
export function Loading(params: LoadingParams) {
    if (params.isLoading) {
        if (params.fullscreen) {
            return <LoadingFullScreen />;
        }
        return (
            <span>
                <LoadingAnimation />
            </span>
        );
    }
    return null;
}

type ErrorParams = {
    children: ReactChildren;
};

export function FormError(params: ErrorParams) {
    return <section className="rounded border-2 border-red-700 bg-red-200 p-2 text-red-700">{params.children}</section>;
}

export function FormStatus(params: ErrorParams) {
    return (
        <section className="p2 rounded border-2 border-yellow-700 bg-yellow-200 text-yellow-800">
            {params.children}
        </section>
    );
}

type ErrorLabelParams = {
    errors: string[] | string | null;
    className?: string;
};

export function ErrorLabel({ errors, className }: ErrorLabelParams) {
    const errorDefaultStyles = 'text-xs text-error-700';

    if (!errors) {
        return null;
    }

    if (!Array.isArray(errors)) {
        return (
            <section>
                <div className={className ?? errorDefaultStyles}>{errors}</div>
            </section>
        );
    }

    return (
        <section>
            {errors.map((e, idx) => (
                <div key={idx} className={className ?? errorDefaultStyles}>
                    {e}
                </div>
            ))}
        </section>
    );
}

type ErrorPanelParams = {
    error: unknown | null;
};

export function ErrorPanel(params: ErrorPanelParams) {
    const e = params.error;
    if (!e) {
        return null;
    }

    if (e instanceof AxiosError) {
        if (e.response) {
            const rsp = e.response;
            const data = rsp.data as StandardErrorData;
            if (data) {
                const title = data.title ?? 'A system error occurred';

                return (
                    <FormErrorPanel>
                        <p>{title}</p>
                    </FormErrorPanel>
                );
            }
        }
    }

    if (e instanceof Error) {
        return <FormErrorPanel>{e.message ?? 'A system error occurred'}</FormErrorPanel>;
    }

    const p = e as PError;
    if (p && p.response && p.response.data) {
        return <FormErrorPanel>{p.response.data.title ?? 'Error'}</FormErrorPanel>;
    }

    return <FormErrorPanel>An error occurred</FormErrorPanel>;
}

type ErrorDisplayParams = {
    children: ReactChildren;
    useMargins?: boolean;
};

export function FormErrorPanel({ children, useMargins = true }: ErrorDisplayParams) {
    const margins = useMargins ? 'm-4' : '';
    return (
        <section className={`${margins} rounded-sm border border-error-600 bg-red-100 p-4 text-error-600`}>
            {children}
        </section>
    );
}

export type StandardErrorData = {
    title: string;
};

type AxiosLabelParams = {
    error: AxiosError;
};

export function AxiosErrorLabel(params: AxiosLabelParams) {
    const response = params.error.response;
    if (response) {
        const data = response.data as StandardErrorData;
        if (data) {
            const title = data.title;
            return <div className="text-red-700">{title}</div>;
        }
    }
    return null;
}

type FormFieldError = {
    errors: string[] | null;
};

export type FormErrorType = { [name: string]: FormFieldError } | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractError(formError: any | null): FormErrorType {
    const e = formError?.response?.data?.errors;
    return e;
}

export function findError(formError: unknown | null, name: string): string[] | null {
    const e = extractError(formError);
    if (!e) {
        return null;
    }

    const fieldError = e[name];
    if (fieldError && fieldError.errors) {
        return fieldError.errors;
    }

    return null;
}

type CharactersRemainingParams = {
    count: number;
    max: number;
    className?: string;
};

export function CharactersRemaining({ count, max, className }: CharactersRemainingParams) {
    const n = Math.max(max - count, 0);

    return <div className={className}>{n} characters remaining</div>;
}
